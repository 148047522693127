// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/about-us.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-career-tsx": () => import("./../../../src/templates/career.tsx" /* webpackChunkName: "component---src-templates-career-tsx" */),
  "component---src-templates-contacts-tsx": () => import("./../../../src/templates/contacts.tsx" /* webpackChunkName: "component---src-templates-contacts-tsx" */),
  "component---src-templates-h-2-s-service-tsx": () => import("./../../../src/templates/h2s-service.tsx" /* webpackChunkName: "component---src-templates-h-2-s-service-tsx" */),
  "component---src-templates-main-tsx": () => import("./../../../src/templates/main.tsx" /* webpackChunkName: "component---src-templates-main-tsx" */),
  "component---src-templates-politics-tsx": () => import("./../../../src/templates/politics.tsx" /* webpackChunkName: "component---src-templates-politics-tsx" */),
  "component---src-templates-service-center-tsx": () => import("./../../../src/templates/service-center.tsx" /* webpackChunkName: "component---src-templates-service-center-tsx" */),
  "component---src-templates-sft-service-tsx": () => import("./../../../src/templates/sft-service.tsx" /* webpackChunkName: "component---src-templates-sft-service-tsx" */),
  "component---src-templates-verification-laboratory-tsx": () => import("./../../../src/templates/verification-laboratory.tsx" /* webpackChunkName: "component---src-templates-verification-laboratory-tsx" */)
}

